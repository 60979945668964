import axios from "axios";
import React, {createContext, useEffect, useState} from "react";
import BASE_URL from "../config";

export const CategoryContext = createContext()
const CategoryProvider = (props) => {
    const [categories, setCategories] = useState([])
    const [subCategories, setSubCategories] = useState([])
    const fetchCategories = async() =>{
        try {
                
            const response = await axios.get(`${BASE_URL}/category`)
            if (response) {
                setCategories(response.data.categories)
            }
        } catch (error) {
            console.log(error.message)
        }
    }
    const fetchSubCategories = async() => {
        try {
            const response = await axios.get(`${BASE_URL}/subcategory`)
            if (response) {
               setSubCategories(response.data.subCategories)
            }
        } catch (error) {
            console.log(error.message)
        }
    }
    useEffect(() => {
        fetchCategories()
        fetchSubCategories()
    }, [])
    return (
        <CategoryContext.Provider
        value={{
            categories, subCategories, fetchCategories, fetchSubCategories
        }}
        >
            {props.children}
        </CategoryContext.Provider>
    )
}

export default CategoryProvider