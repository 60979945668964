import React, { useContext } from "react";
import Footer from "../../components/shared/Footer";
import FlashSales from "../../components/user/FlashSales";
import Categories from "../../components/user/Categories";
import Hero from "../../components/user/Hero";
import BestSelling from '../../components/user/BestSelling'
import BuyNow from "../../components/user/BuyNow";
import Explore from "../../components/user/Explore";
import Arrival from "../../components/user/Arrival";
import BeforeFooter from "../../components/user/BeforeFooter";
import NavBar from "../../components/shared/NavBar";
import CategoryProvider from "../../context/CategoryContext";
import { UserContext } from "../../context/UserContext";

const Dashboard = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    
    return (
        <>
        <NavBar />
        <Hero />
        <FlashSales />
        <CategoryProvider>
            <Categories />
        </CategoryProvider>
        <BestSelling />
        <BuyNow />
        <Explore />
        <Arrival />
        <BeforeFooter />
        <Footer />
        </>
    )
} 

export default Dashboard