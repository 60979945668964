import React, { createContext, useEffect, useReducer } from "react";
import axios from "axios";
import BASE_URL from '../config.js'

export const ProductContext = createContext()
const ProductReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_PRODUCT':
            break;
        case 'SET_PRODUCT':
            return {
                ...state,
                products: action.payload
            }
        default:
            return state
    }
}
const initialState = {
    products: []
}
export const ProductProvider = (props) => {
    const [state, dispatch] = useReducer(ProductReducer, initialState)
    useEffect(() => {
        const fetchProducts = async() => {
            await axios.get(`${BASE_URL}/product`)
                        .then((response) => {
                            dispatch({
                                type: 'SET_PRODUCT',
                                payload: response.data.products
                            })
                        })
                        .catch((error) => {
                            console.log(error.response?.data)
                        })
        }
        fetchProducts()
    })
    return (
        <ProductContext.Provider
        value={{
            ...state,
            dispatch
        }}
        >
            {props.children}
        </ProductContext.Provider>
    )
}

export default ProductProvider