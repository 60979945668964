import React, {createContext, useEffect, useReducer, useState} from 'react'

export const UserContext = createContext()

const UserProvider = (props) => {
    const [user, setUser] = useState({})
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    useEffect(() => {
        const isLoggedIn = localStorage.getItem('isLoggedIn')
        const user = localStorage.getItem('user')
        setIsLoggedIn(isLoggedIn)
        setUser(user)
    }, [localStorage])
    return (
        <UserContext.Provider
        value={{
            user, isLoggedIn
        }}
        >
            {props.children}
        </UserContext.Provider>
    )
}

export default UserProvider