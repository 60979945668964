import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Button, Card, Container, Dropdown, Form, FormLabel, InputGroup, Row, Spinner } from "react-bootstrap";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { CategoryContext } from "../../context/CategoryContext";
import { ProductContext } from "../../context/ProductContext";
import BASE_URL from '../../config';

const ProductForm = ({ formData, handleInput, handleFileUpload, handleSubmit, loading, successMessage, errorMessages, SubCatSearchText, filteredSubCatOptions, handleSubCatSearch, handleSubCatSelectOption, selectedSubCatOption, fileInputRef }) => (
    <Form className="m-3 d-flex flex-column align-content-center justify-content-center" onSubmit={handleSubmit}>
        <Form.Control 
            name="name"
            type="text"
            placeholder="Product Name"
            required
            className="my-3"
            value={formData.name}
            onChange={handleInput}
        />
        <Dropdown>
            <Dropdown.Toggle className="bg-transparent border-0 text-black">
                {selectedSubCatOption || 'Select Subcategory'}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Form.Control 
                    autoFocus
                    name="subcategory"
                    placeholder="Type to search"
                    value={SubCatSearchText}
                    onChange={handleSubCatSearch}
                    onClick={(e) => e.stopPropagation()}
                    style={{ marginBottom: '5px' }}
                />
                {filteredSubCatOptions.map((option, index) => (
                    <Dropdown.Item key={index} onClick={() => handleSubCatSelectOption(option)}>
                        {option.name}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
        <Form.Control 
            name="description"
            type="text"
            placeholder="Product Description"
            required
            className="my-3"
            value={formData.description}
            onChange={handleInput}
        />
        <Form.FloatingLabel id="qty" className="my-3" label="Available Quantity">
            <Form.Control 
                name="qty"
                type="text"
                placeholder="Available Quantity"
                required
                value={formData.qty}
                onChange={handleInput}
            />
        </Form.FloatingLabel>
        <Form.FloatingLabel className="my-3" label="Product Price">
            <Form.Control 
                name="price"
                type="text"
                placeholder="Product Price"
                required
                value={formData.price}
                onChange={handleInput}
            />
        </Form.FloatingLabel>
        <Form.Control name='id' type="hidden" value={formData.id} />
        <FormLabel htmlFor="images" className="align-self-center">Upload three images of the product</FormLabel>
        <InputGroup className="flex flex-row align-items-center gap-2 justify-content-center" id='images'>
            {[0, 1, 2].map((index) => (
                <Form.Control
                    name="images" 
                    type="file"
                    key={index}
                    required
                    ref={fileInputRef[index]}
                    className="my-3 w-auto"
                    accept="image/*"
                    onChange={(e) => handleFileUpload(e, index)}
                />
            ))}
        </InputGroup>
        {loading && (
            <Spinner animation="border" role="status" className="d-flex align-self-center justify-content-center">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        )}
        <i className="text-success text-center">{successMessage}</i>
        <i className="text-danger text-center">{errorMessages}</i>
        <Button className="btn-danger" type="submit">Add Product</Button>
    </Form>
);

const ProductCard = ({ product, editProduct, deleteProduct }) => (
    <Card className="col-sm-12 col-lg-4 col-xl-4 col-md-5">
        <Card.Title>{product.name}</Card.Title>
        <Card.Img src={`${BASE_URL}/${product.images[0]}`} alt='product image' />
        <Card.Body>
            <Card.Text>{product.description}</Card.Text>
        </Card.Body>
        <Card.Footer className="d-flex gap-3 align-items-center">
            <Button onClick={() => editProduct(product)}>Edit</Button>
            <Button onClick={() => deleteProduct(product._id)}>Delete</Button>
        </Card.Footer>
    </Card>
);

const Product = () => {
    const { subCategories, fetchSubCategories } = useContext(CategoryContext);
    const { products } = useContext(ProductContext);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        category: '',
        subCategory: '',
        description: '',
        qty: 0,
        price: 0,
        id: '',
        images: [null, null, null]
    });
    const [SubCatSearchText, setSubCatSearchText] = useState('');
    const [selectedCatOption, setSelectedCatOption] = useState('');
    const [selectedSubCatOption, setSelectedSubCatOption] = useState('');
    const [errorMessages, setErrorMessages] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [token, setToken] = useState('');
    const [loading, setLoading] = useState(false);
    const [filteredSubCatOptions, setfilteredSubCatOptions] = useState(subCategories);
    const fileInputRef = [useRef(null), useRef(null), useRef(null)];

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate(`${BASE_URL}/login`, 'Session has expired, please login!');
        }
        setToken(token);
        fetchSubCategories()
    }, [navigate, fetchSubCategories]);


    const handleInput = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    };

    const handleSubCatSearch = (e) => {
        const text = e.target.value;
        setSubCatSearchText(text);
        const filtered = subCategories.filter(option => 
            option.name.toLowerCase().includes(text.toLowerCase())
        );
        setfilteredSubCatOptions(filtered);
    };

    const handleSubCatSelectOption = (option) => {
        setSelectedSubCatOption(option.name);
        setFormData(prevFormData => ({ ...prevFormData, subCategory: option._id }));
        setSubCatSearchText('');
    };

    const handleFileUpload = (e, index) => {
        const files = [...formData.images];
        files[index] = e.target.files[0];
        setFormData(prevFormData => ({ ...prevFormData, images: files }));
    };

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        if (formData.images.length !== 3) {
            setErrorMessages('Please upload exactly three images of product!');
            return;
        }
        if (!selectedCatOption) {
            setErrorMessages('Please select product category!');
            return;
        }
        const productForm = new FormData();
        productForm.append('name', formData.name);
        productForm.append('category', formData.category);
        productForm.append('subCategory', formData.subCategory);
        productForm.append('description', formData.description);
        formData.images.forEach((image) => {
            productForm.append('images', image);
        });
        productForm.append('qty', formData.qty);
        productForm.append('price', formData.price);
        setLoading(true);
        try {
            const response = formData.id ? 
                await axios.put(`${BASE_URL}/product/${formData.id}`, productForm, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`
                    }
                }) :
                await axios.post(`${BASE_URL}/product`, productForm, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`
                    }
                });
            setLoading(false);
            setSuccessMessage(response.data.message);
            setTimeout(() => setSuccessMessage(''), 2000);
            setErrorMessages('');
            setFormData({
                name: '',
                category: '',
                subCategory: '',
                description: '',
                qty: 0,
                price: 0,
                id: '',
                images: [null, null, null]
            });
            fileInputRef.forEach((input) => {
                input.current.value = null;
            });
            setSelectedCatOption('');
            setSelectedSubCatOption('');
        } catch (err) {
            setLoading(false);
            if (err.response?.status === 401) {
                navigate('/login', { state: { message: "Session expired, please login to continue!" } });
            }
            setSuccessMessage('');
            setErrorMessages(err.response.data);
        }
    }, [formData, token, selectedCatOption, navigate, fileInputRef]);

    const editProduct = (product) => {
        const { _id, name, images, category, subCategory, description, qty, price } = product;
        setFormData({ id: _id, name, images, category, subCategory, description, qty, price });
        const subCatOption = subCategories.find(option => option._id === subCategory)
        handleSubCatSelectOption(subCatOption);
    };

    const deleteProduct = async (id) => {
        try {
            await axios.delete(`${BASE_URL}/product/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
        } catch (err) {
            if (err.response.status === 401) {
                navigate('/login', { state: { message: "Session expired, please login to continue!" } });
            }
            setSuccessMessage('');
            setErrorMessages(err.response.data);
        }
    };

    return (
        <Container>
            <Row>
                <ProductForm 
                    formData={formData} 
                    handleInput={handleInput}
                    handleFileUpload={handleFileUpload}
                    handleSubmit={handleSubmit}
                    loading={loading}
                    successMessage={successMessage}
                    errorMessages={errorMessages}
                    subCategories={subCategories}
                    SubCatSearchText={SubCatSearchText}
                    setSubCatSearchText={setSubCatSearchText}
                    filteredSubCatOptions={filteredSubCatOptions}
                    handleSubCatSearch={handleSubCatSearch}
                    handleSubCatSelectOption={handleSubCatSelectOption}
                    selectedSubCatOption={selectedSubCatOption}
                    fileInputRef={fileInputRef}
                />
            </Row>
            <Row className="flex flex-row align-content-between gap-3 m-3">
                {products.map((product) => (
                    <ProductCard 
                        key={product._id}
                        product={product}
                        editProduct={()=>editProduct(product)}
                        deleteProduct={()=>deleteProduct(product._id)}
                    />
                ))}
            </Row>
        </Container>
    );
};

export default Product;