import React, { useState } from 'react'
import {Row, Col, Button, Card, Container, ListGroup } from 'react-bootstrap'
import { Icons } from '../../icons'
import gamepad from '../../assets/gamepad.png'

const {FaRegHeart, FaArrowRight, FaArrowLeft, FaRegEye, FaStar} = Icons

const FlashSales = () => {
    const sample = [
        {
            name: 'Game pad',
            description: 'Latest Game pad',
            image: gamepad,
            price: '$120',
            rating: [1, 2, 3, 4, 5]
        }
    ]
    const [flashDate, setFlashDate] = useState()
    const [rating, setRating] = useState(2)
    const [flashSales, setFlashSales] = useState(sample)
    const handleStarClick = (newRating) => {
        setRating(newRating)
    }
    return (
        <Container className='mx-auto my-2'>
            <Row>
                <h5 className='text-danger'>
                <span className="fw-bold mx-3 bg-danger p-1">I</span>
                Today's
                </h5>
            </Row>
            <Row className='d-flex mx-auto align-items-end'>
                <Col xl={3}>
                    <h6 className='fw-semibold ff-Inter'>
                    Flash Sales
                    </h6>
                </Col>
                <Col>
                    <ListGroup className='d-flex align-items-center'>
                        <ListGroup.Item className='d-flex flex-start gap-2'>
                            <span className='mx-1'>Days</span>
                            <span className='mx-1'>Hours</span>
                            <span className='mx-1'>Minutes</span>
                            <span className='mx-1'>Seconds</span>
                        </ListGroup.Item>
                        <ListGroup.Item className='d-flex flex-start gap-2'>
                            <span className='mx-2 fw-bold fs-4'>03</span>
                            <span className='mx-2 fw-bold fs-4'>:</span>
                            <span className='mx-2 fw-bold fs-4'>05</span>
                            <span className='mx-2 fw-bold fs-4'>:</span>
                            <span className='mx-2 fw-bold fs-4'>04</span>
                            <span className='mx-2 fw-bold fs-4'>:</span>
                            <span className='mx-2 fw-bold fs-4'>06</span>
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
                <Col xl={1}>
                    <Button className="bg-transparent border-0 text-black">
                        <FaArrowLeft />
                    </Button>
                    <Button className="bg-transparent border-0 text-black">
                        <FaArrowRight />
                    </Button>
                </Col>
            </Row>
            <Row>
                {
                    flashSales.map((product, index) => (
                    <Card style={{width: "270px", height: "370px", backgroundColor: "#F5F5F5", border: 0}}
                    key={index}
                    >
                        <Card.ImgOverlay>
                            <Row>
                                <Col xl={3} xs={3} md={3}>
                                    <Card.Text className='bg-danger text-white' style={{padding: "2px"}}>-41%</Card.Text>
                                </Col>
                                <Col style={{width: "34px", height: "76px", marginLeft: "224px", gap: "8px", marginTop: "12px"}}>
                                <FaRegHeart />
                                <FaRegEye />
                                </Col>
                            </Row>
                        </Card.ImgOverlay>
                        <Card.Img src={product.image} 
                            style={{width: "175px", height: "152px", margin: '40px'}} />
                        <Card.Body style={{backgroundColor: "white"}}>
                            <Card.Text className='fw-bold'>{product.description}</Card.Text>
                            <Card.Text>
                                <span style={{color: "#DB4444", font: "Poppins"}}>{product.price}</span>
                                <span className='text-decoration-line-through ps-2' style={{color: '#000000', font: "Poppins"}}>$140</span>
                            </Card.Text>
                            {product.rating.map((star) => (
                                <FaStar
                                    key={star}
                                    onClick={() => handleStarClick(star)}
                                    style={{cursor: 'pointer', marginRight: '5px',
                                    color: star <= rating ? '#FFAD33' : '#000000'
                                }}
                                />
                            ))}
                        </Card.Body>
                    </Card>
                    ))
                }
            </Row>
            <Row className='d-flex justify-content-center'>
            <Button className='p-2 bg-danger text-center col-xl-2 col-sm-4 w-auto'>View All Products</Button>
            </Row>
        </Container>
    )
}

export default FlashSales