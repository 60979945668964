import React, {useState, useEffect, useContext} from "react";
import Category from '../../components/admin/Category';
import Product from '../../components/admin/Product';
import ManageProduct from "../../components/admin/ManageProducts";
import NavBar from '../../components/shared/NavBar'
import Footer from '../../components/shared/Footer'
import { Container, Row, Col, Nav, Tab} from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import SubCategory from "../../components/admin/SubCategory";
import ProductProvider from "../../context/ProductContext";

const AdminDashboard = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    const navigator = useNavigate()
    const token = localStorage.getItem('token')
    const isLoggedIn = localStorage.getItem('isLoggedIn')
    useEffect(() => {
        if (!isLoggedIn || !token){
            navigator('/login', {message: "You need to login first!"})
        }
        if (isLoggedIn && user.role !== 'admin') {
            navigator('/access-denied')
        }
    }, [isLoggedIn, navigator, token, user])
    return (
        <>
        <NavBar />
            <Container fluid>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                        <Col sm={3} xl={3} lg={3}>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                            <Nav.Link eventKey="first" variant='dark'>Category</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                            <Nav.Link eventKey="second">Sub Category</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                            <Nav.Link eventKey="third">Product</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                            <Nav.Link eventKey="fourth">Manage Products</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        </Col>
                        <Col sm={9}>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                    <Category />
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <SubCategory />
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                                <ProductProvider>
                                    <Product />
                                </ProductProvider>
                            </Tab.Pane>
                            <Tab.Pane eventKey="fourth">
                                <ProductProvider>
                                    <ManageProduct />
                                </ProductProvider>
                            </Tab.Pane>
                        </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>
        <Footer />
        </>
    )
}

export default AdminDashboard;