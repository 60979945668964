import React from "react";
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import Home from "./pages/shared/Home";
import About from "./pages/user/About"
import Contact from "./pages/user/Contact"
import Cart from "./pages/user/Cart"
import NotFound from "./pages/shared/NotFound"
import WishList from "./pages/user/WishList"
import Login from "./pages/shared/Login"
import SignUp from "./pages/shared/SignUp"
import Dashboard from "./pages/user/Dashboard";
import AdminDashboard from "./pages/admin/Dashboard";
import CartProvider from "./context/CartContext";
import CategoryPage from "./pages/user/Category"
import CategoryProvider from "./context/CategoryContext";
import AccessDenied from "./pages/shared/AccessDenied";
import WishlistProvider from "./context/WishlistContext";
function App() {
  return (
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contactus" element={<Contact />} />
          <Route path="/*" element={<NotFound />} />
          <Route path="/access-denied" element={<AccessDenied />} />
          <Route path="/cart" element={
          <CartProvider>
            <Cart />
          </CartProvider>
          } />
          <Route path="/wishlist" element={
          <WishlistProvider>
            <WishList />
          </WishlistProvider>
          } />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/admin/dashboard" element={
          <CategoryProvider>
            <AdminDashboard/>
          </CategoryProvider>
          } />
          <Route path="/categories/:id" element={
          <CategoryProvider>
            <CategoryPage/>
          </CategoryProvider>
          } />
        </Routes>
      </BrowserRouter>
  );
}

export default App;