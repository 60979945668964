import React from "react";
import NavBar from "../../components/shared/NavBar";
import Footer from "../../components/shared/Footer";
import FlashSales from "../../components/user/FlashSales";
import Categories from "../../components/user/Categories";
import Hero from "../../components/user/Hero";
import BestSelling from '../../components/user/BestSelling'
import BuyNow from "../../components/user/BuyNow";
import Explore from "../../components/user/Explore";
import Arrival from "../../components/user/Arrival";
import BeforeFooter from "../../components/user/BeforeFooter";

const Home = () => {
    return (
        <>
        <NavBar />
        <Hero />
        <FlashSales />
        <Categories />
        <BestSelling />
        <BuyNow />
        <Explore />
        <Arrival />
        <BeforeFooter />
        <Footer />
        </>
    )
}

export default Home