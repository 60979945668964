import React, {useContext, useEffect} from "react";
import NavBar from "../../components/shared/NavBar";
import Footer from "../../components/shared/Footer";
import { Container, Row } from "react-bootstrap";
import { CategoryContext } from "../../context/CategoryContext";
import { useParams } from "react-router-dom";


const CategoryPage = () => {
    const {id} = useParams()
    const {categories, dispatch, category} = useContext(CategoryContext)
    useEffect(() => {
        const selectedCategory = categories.find(category => category._id === id)
        dispatch({
            type: 'SET_CATEGORY',
            payload: selectedCategory
        })

    }, [id, categories, dispatch])
    return (
        <>
        <NavBar />
        <Container>
            <Row>
                <h1 className="text-danger text-center">{
                    category && category.name
                    }</h1>
            </Row>
        </Container>
        <Footer />
        </>
    )
}

export default CategoryPage